<template>
    <MeasurementItem>
        <template #content>
            <div v-if="isLoading" class="no-padding l-full-width">
                <VSpinner size="medium" line-fg-color="black" :speed="1" />
            </div>

            <div v-else class="l-full-width">
                <ApexChart
                    :options="chartOptions"
                    :series="series"
                    :height="chartHeight"
                />
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import moment from 'moment'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '../utils'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'TruckTimelineMeasurementFragment',
    components: {
        ApexChart,
        MeasurementItem,
        VSpinner,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '50%',
                        rangeBarGroupRows: true,
                    },
                },
                colors: ['#d32f2f', '#00a1de', '#fdae61', '#41b883'],
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: {
                    showAlways: true,
                },
                tooltip: {
                    x: {
                        format: 'HH:mm',
                    },
                },
            },
            isLoading: true,
            series: [],
        }
    },
    computed: {
        chartHeight() {
            let xFirst
            const isMultiSeries = !this.series.every(({ data }) =>
                data.every(({ x }) => {
                    if (x && !xFirst) {
                        xFirst = x
                    }
                    return x === xFirst
                })
            )
            return isMultiSeries ? 200 : 140
        },
    },
    watch: {
        'sensorData.driver_id': {
            immediate: true,
            async handler() {
                try {
                    this.isLoading = true
                    const { data } = await httpHelper.get(
                        '/tachograph/driver-activities/',
                        {
                            params: {
                                driver_id: this.sensorData.driver_id?.value,
                                start: moment()
                                    .subtract(1, 'day')
                                    .startOf('day')
                                    .toDate(),
                                end: moment()
                                    .endOf('day')
                                    .toDate(),
                            },
                        }
                    )

                    const today = new Date().toJSON().slice(0, 10)
                    const activities = data.results.sort(
                        (a, b) => new Date(b.start) - new Date(a.start)
                    )

                    this.series = ['Drive', 'Work', 'Daily rest', 'Rest'].map(
                        name => ({
                            name,
                            data: activities
                                .filter(
                                    ({ activity }) =>
                                        activity === name ||
                                        (!activity && name === 'Daily rest')
                                )
                                .map(({ start, end }) => ({
                                    x:
                                        start > today
                                            ? this.$t('today')
                                            : this.$t('yesterday'),
                                    y: [
                                        new Date(
                                            today + start.slice(10)
                                        ).getTime(),
                                        new Date(
                                            today + end.slice(10)
                                        ).getTime(),
                                    ],
                                })),
                        })
                    )
                } finally {
                    this.isLoading = false
                }
            },
        },
    },
}
</script>

<i18n>
{
    "en": {
        "today": "Today",
        "yesterday": "Yesterday"
    },
    "de": {
        "today": "Heute",
        "yesterday": "Gestern"
    },
    "fr": {
        "today": "Aujourd'hui",
        "yesterday": "Hier"
    },
    "it": {
        "today": "Oggi",
        "yesterday": "Ieri"
    }
}
</i18n>
